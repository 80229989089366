<template>
  <div :class="$style.container">
    <div :class="$style.header">
      <div
        v-for="item in columns"
        :key="item.key"
        :style="{ flex: item.width }"
      >
        {{ item.title }}
      </div>
      <div v-if="showArrow" :class="$style.arrow"></div>
    </div>
    <div :class="$style.rowContent">
      <div
        :class="[$style.row, showArrow ? $style.point : '']"
        v-for="(value, index) in data"
        :key="index"
        @click.stop="$emit('rowClick', value)"
      >
        <div
          v-for="item in columns"
          :key="item.key"
          :style="{ flex: item.width }"
        >
          {{ value[item.key] }}
        </div>
        <div v-if="showArrow" :class="$style.arrow">
          <span class="TC tc-icon-jiantou1"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Vue, Prop, Component } from 'vue-property-decorator';
@Component
export default class SafeModal extends Vue {
  @Prop({ type: Array, required: true }) columns;
  @Prop({ type: Array, required: true }) data;
  @Prop({ type: Boolean, required: false }) showArrow;
}
</script>

<style lang="less" module>
.container {
  .header,
  .row {
    display: flex;
    flex-wrap: nowrap;
    align-items: center;
    height: 0.4rem;
    font-size: 0.14rem;
    padding-left: 0.2rem;
    > div {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .rowContent {
    padding: 0 0.2rem;
    max-height: 2.82rem;
    overflow-y: auto;
  }
  .header {
    background-color: #032649;
    margin: 0 0.2rem;
  }
  .row {
    color: #fff;
    &:nth-child(even) {
      background-color: #032649;
    }
  }
  .row > div:first-child {
    color: #04b0d8;
    padding-right: 2em;
  }
  .arrow {
    flex: 30;
    font-size: 0.12rem;
  }
  .point {
    cursor: pointer;
  }
}
</style>
