import { GlobalSocket } from '@triascloud/message-hub';
import { createModuleUrl } from '@/utils';
import { getWebsocketHomeId } from '@/services/iot-electric-box/socket.js';
/** @name webSocket链接地址 */
export const SOCKET_URL = createModuleUrl('api', 'https:');

/** @name 初始化房间号id */
export async function initSocketHomeId(projectId) {
  try {
    return await getWebsocketHomeId(projectId);
  } catch (error) {
    return '';
  }
}

export default class GlobalWebSocket {
  static sockets = new Map();
  static getSocketUrl(options) {
    const { homeId, token } = options;
    if (homeId) {
      return `${SOCKET_URL}?appId=${homeId}`;
    } else if (token) {
      return `${SOCKET_URL}?X-Access-Token=${token}`;
    }
  }
  /** @name 需要传入消息房间homeId或token */
  constructor(options = {}) {
    this.options = options;
    let socketUrl = '';
    const socket = this.webSocketMatch();
    if (socket) {
      this.socket = socket;
      return;
    }
    socketUrl = GlobalWebSocket.getSocketUrl(this.options);
    /** @type { import('@triascloud/message-hub').GlobalSocket } */
    this.socket = new GlobalSocket();
    this.socket.init(socketUrl);
    this.webSocketCache(this.socket);
  }

  /**@name 单例模式匹配socket */
  webSocketMatch() {
    const { homeId, token } = this.options;
    const target = homeId || token;
    return GlobalWebSocket.sockets.get(target);
  }

  /**@name 单例模式缓存socket */
  webSocketCache(socket) {
    const { homeId, token } = this.options;
    const target = homeId || token;
    return GlobalWebSocket.sockets.set(target, socket);
  }
  /** @name 事件接收 */
  webSocketServerListener(callback) {
    this.socket.on('SERVER_EVENT', callback);
  }

  /** @name 事件发送 */
  webSocketEventSend(event, options) {
    this.socket.emit(event, options);
  }
  /**
   * @name 关闭该websocket
   */
  close() {
    this.socket.close();
  }
}
