import request from '../request';
/**
 * @name 获取webscoket房间id
 */
export function getWebsocketHomeId(projectId) {
  return request(`/iot-electric-box/socket/appId/${projectId}`, {
    method: 'GET',
    auth: false,
    body: { projectId },
    closeMessage: true,
  });
}
