<template>
  <span :class="$style.btn" @click="handleToggleFullScreen">
    <img src="@/assets/images/screen/full.png" v-if="!isFullScreen" />
    <img v-else src="@/assets/images/screen/back-full.png" />
    <span v-show="showText" :class="$style.txt">{{
      isFullScreen ? $t('screen.exitFullScreen') : $t('screen.fullScreen')
    }}</span>
  </span>
</template>

<script>
import { fullScreen } from '@/utils/common.js';
import { Component, Vue, Prop } from 'vue-property-decorator';
@Component
export default class fullScreenBtn extends Vue {
  @Prop({ default: false, type: Boolean }) showText;
  isFullScreen = false;
  handleToggleFullScreen() {
    fullScreen(!this.isFullScreen);
  }
  mounted() {
    this.setIsFillScreen();
    addEventListener('resize', this.setIsFillScreen);
    addEventListener('keydown', this.preventKeyDown);
  }
  setIsFillScreen() {
    this.isFullScreen = Boolean(
      document.body.scrollHeight == window.screen.height &&
        document.body.scrollWidth == window.screen.width,
    );
  }
  preventKeyDown(e) {
    if (e.key === 'F11') {
      e.preventDefault();
      this.handleToggleFullScreen();
    }
  }
  destroy() {
    removeEventListener('keydown', this.preventKeyDown);
    removeEventListener('resize', this.setIsFillScreen);
  }
}
</script>

<style lang="less" module>
.btn {
  display: flex;
  justify-content: space-around;
  align-items: center;
  white-space: nowrap;
  .txt {
    margin-left: 0.1rem;
  }
}
</style>
